@import './variables';
@import './fonts';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  @include Creepster;
  font-size: 18px;
  box-sizing: border-box;
  touch-action: manipulation;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}

html,
body,
#root {
  background: #2B002F;
  accent-color: #880294;
}
body {
}

h1,
h2{
  @include Butcherman;
}

h3,
h4{
  @include Creepster;
}

p{
  font-size: 20px;
}

.form{
  margin: 0 auto;
  max-width: 350px;

  &__button{
    padding: 10px 20px;
    display: block;
    width: 100%;
    font-size: 24px;
    color: red;
    cursor: pointer;
  }

  &-group{
    text-align: left;
    margin-bottom: 20px;

    &--checkbox{
      & > label span{
        display: inline-block;
        margin-right: 5px;
      }
    }

    label{
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      width: 100%;
    }

    &__sbs{
      flex-direction: row!important;
      align-items: center!important;
    }

    &__label{
      margin-bottom: 5px;
      display: block;
    }
    &__field{

    }
    &__error{
      @include OpenSans;
      font-size: 12px;
      font-weight: bold;
      color: #f00;
      padding-top: 5px;
    }
  }
}

input{
  padding: 10px 10px;
  color: red;
  width: 100%;
}

input::placeholder{
  font-size: 13px;
}

input[type="checkbox"]{
  width: 35px;
  height: 35px;
}
