/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../assets/fonts/open-sans-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../assets/fonts/open-sans-v16-latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v16-latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v16-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v16-latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v16-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/open-sans-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../assets/fonts/open-sans-v16-latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v16-latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v16-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v16-latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v16-latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../assets/fonts/open-sans-v16-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../assets/fonts/open-sans-v16-latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v16-latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v16-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v16-latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v16-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../assets/fonts/open-sans-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../assets/fonts/open-sans-v16-latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/open-sans-v16-latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('../assets/fonts/open-sans-v16-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../assets/fonts/open-sans-v16-latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('../assets/fonts/open-sans-v16-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* butcherman-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Butcherman';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/butcherman-v24-latin-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  url('../assets/fonts/butcherman-v24-latin-regular.ttf') format('truetype'); /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
}
/* creepster-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Creepster';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/creepster-v13-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
